import { Box, Button } from "@mui/material";
import { MultiSelect } from "components/common/FormComponents";
import Select from "components/common/FormComponents/Select";
import PopUp from "components/common/PopUp";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ModuleItem } from "types/api";
import { ModuleName, ModuleSettingType } from "types/enum";
import errorMessage from "constants/errorMessage";
import Toggle from "components/common/Toggle";
import { useAppSelector } from "redux/store";
import useActions, { useCurrentSetting } from "./actions";
import { useQuery, useQueryClient } from "react-query";
import { getAllResidents } from "utils/api/user";

const settingTypes = [
    { label: "All Resident", value: ModuleSettingType.CareHome },
    { label: "Specified Residents", value: ModuleSettingType.Resident },
];

const modules = [
    { label: "Care Hub", value: ModuleName.CareHomeHub },
    { label: "Medication", value: ModuleName.Medication },
    { label: "Reflection", value: ModuleName.Reflection },
    { label: "Core Health", value: ModuleName.CoreHealth },
    { label: "Nutrition", value: ModuleName.Nutrition },
    { label: "Family Whiteboard", value: ModuleName.FamilyWhiteboard },
]


const NewSettingDialog = () => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const queryClient = useQueryClient();
    const { pccId } = useAppSelector((state) => state.pccInfo);
    const {
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        getValues,
        clearErrors,
        reset,
    } = useForm<ModuleItem>({
        mode: "onChange",
        defaultValues: {
            settingType: ModuleSettingType.CareHome,
            modules: [],
            careHomeId: pccId,
        },
    });

    const onDone = () => {
        setOpen(false);
        reset();
        queryClient.invalidateQueries("getSettings");
    }

    const { data: currentSettings } = useCurrentSetting();

    const { createModuleSetting, loading } = useActions(onDone);

    const { data: residentData
    } = useQuery({
        queryKey: ["getResidentForSettings", pccId, search],
        queryFn: () => getAllResidents({ pccId, page: 1, search, perPage: 20 }),
    });

    const residentsOptions = useMemo(() => {
        const residents = residentData?.data?.data || [];
        return residents.map((resident) => ({
            label: `${resident.firstName} ${resident.lastName}`,
            value: resident.id,
        }));
    }, [residentData]);

    const [selectedModules, settingType] = watch(["modules", "settingType"]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSearch = (value: string) => {
        console.log(value);
        setSearch(value);
    }

    const onSave = () => {
        const residents = getValues("residents")?.map((item: any) => item.value);
        const settintItem = getValues();
        if (residents) {
            settintItem.residents = residents;
        }
        createModuleSetting({
            settingData: {
                modules: JSON.stringify([
                    ...currentSettings,
                    settintItem
                ])
            }
        });
    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add New Setting
            </Button>
            <PopUp
                open={open}
                handleClose={handleClose}
                heading="Add New Setting"
                actionRight={{ label: loading ? "Saving..." : "Save", disabled: !isValid || loading }}
                actionLeft={{ label: "Cancel", disabled: loading }}
                handleRightBtnClick={onSave}>
                <Box>
                    <Select
                        control={control}
                        label="Setting Type"
                        name={`settingType`}
                        options={settingTypes}
                        errors={errors}
                        rules={{
                            required: errorMessage.required,
                        }}
                    />
                    {settingType === ModuleSettingType.Resident && <MultiSelect
                        control={control}
                        onSearch={onSearch}
                        clearErrors={clearErrors}
                        name="residents"
                        label="Select Residents"
                        typing={false}
                        menuOptions={residentsOptions}
                        rules={{ required: errorMessage.required }}
                        vertical
                    />}
                    <Box fontWeight="bold" fontSize={14} mb={2} mt={2}>Select modules</Box>
                    {modules.map((module) => (
                        <Box display="flex" key={module.value} mb={1} alignItems="center" justifyContent="space-between">
                            <Box> {module.label}</Box>
                            <Toggle
                                value={selectedModules.includes(module.value)}
                                onChange={(checked) => setValue("modules", checked ? [...selectedModules, module.value] : selectedModules.filter(item => item !== module.value))} />
                        </Box>))}
                </Box>
            </PopUp>
        </div>
    );
}

export default NewSettingDialog;
