import { useAppSelector } from "redux/store";
import { Box, Snackbar } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./styles";
import Button from "components/common/Button";
import { Date as DatePicker, Input } from "components/common/FormComponents";
import { useForm } from "react-hook-form";
import { Nutrition } from "types/api";
import Select from "components/common/FormComponents/Select";
import TextArea from "components/common/FormComponents/TextArea";
import { NutritionType } from "types/enum";
import { ChangeEvent, useRef, useState } from "react";
import useActions from "./actions";
import { set } from "lodash";
import { useQueryClient } from "react-query";

const options = [{
    label: "Menu",
    value: NutritionType.Menu,
}, {
    label: "Other",
    value: "other",
}];

interface Props {
    onBack: () => void;
}

const NutritionForm = ({ onBack }: Props) => {
    const ref = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File>(null);
    const [notiVisible, setNotiVisible] = useState(false);

    const client = useQueryClient();
    const {
        control, formState: { errors, isValid }, watch, setValue
    } = useForm<Nutrition>({
        mode: "onChange",
        defaultValues: {},
    });

    const onDone = () => {
        setFile(null);
        onBack();
        setNotiVisible(true);
        client.invalidateQueries("getNutritions");
    }

    const { handleCreate, loading } = useActions(onDone);

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        console.log(files);
        if (files[0]) {
            setFile(files[0]);
        }
    }

    const handleBack = () => {
        onBack();
    }

    const onSubmit = () => {
        handleCreate({ nutrition: { ...watch(), attachment: file } });
    }

    return <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box
                sx={styles.backStyles}
                onClick={handleBack}
                tabIndex={0}
            >
                <ArrowBackIosIcon sx={styles.backArrow} />
                <Box>Back</Box>
            </Box>
            <Button label={loading ? "Processing..." : "Create Nutrition"} size="small" disabled={!isValid || loading} onClick={onSubmit} />
        </Box>
        <Box sx={styles.formWrapper}>
            <Box mb={2}>
                <Input name="name" labelPos control={control} label="Name" rules={{ required: true }} errors={errors} />
            </Box>
            <Box mb={2}>
                <TextArea minRows={3} maxRows={6} name="description" labelPos control={control} label="Description" rules={{ required: true }} errors={errors} />
            </Box>
            <Select
                name="type"
                options={options}
                control={control}
                label="Type"
                rules={{ required: true }}
                customStyle={{ containerWrapper: styles.labelPos, wrapper: styles.selectWrapper }}
                errors={errors} />
            <Box mb={2} />
            <DatePicker
                name="startDate"
                control={control}
                type="date"
                label="From"
                labelPos
                className="date"
                minDate={watch().startDate}
                errors={errors}
                fullWidth
                setValue={setValue}
                rules={{ required: true }}
                customStyles={{
                    input: styles.dateSelector,
                }}
            />
            <Box component={"input"} type="file" accept="application/pdf" ref={ref} sx={styles.input} id="nutrition-attachment" onChange={onFileChange} />
            <Box display="flex" mt={2}>
                <Box fontWeight="bold" fontSize={15} width={110}>Attachment</Box>
                <Button label="Upload Attachment" size="small" onClick={() => ref.current.click()} />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notiVisible}
                onClose={() => setNotiVisible(false)}
                message="Nutrition created successfully"
                key={"email-sent"}
            />
        </Box>
    </Box>
}

export default NutritionForm;